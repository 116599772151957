import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      herovideo: file(relativePath: { eq: "Roofing.mp4" }) {
        publicURL
      }
    }
  `);

  return (
    <div>
      {/* Make the container fill the viewport */}
      <div className="relative w-screen h-screen">
        {/* Keep your video settings, ensuring it covers the entire container */}
        <video 
          autoPlay 
          loop 
          muted 
          playsInline 
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={data.herovideo.publicURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Hero;
