import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const WorkItem = ({ image, title, description, view, animation }) => {
  return (
    <div className={`flex basis-1/2 flex-col ${view ? animation : 'opacity-0'}`}>
      <div className="border-2 border-primary-600">
        <GatsbyImage image={image} alt={title} />
      </div>
      <div className="flex flex-col self-stretch pt-6">
        <h3 className="font-display text-display-md pb-4">{title}</h3>
        <p className="text-body-lg font-light text-neutral-700">
          {description}
        </p>
      </div>
    </div>
  );
};

export default WorkItem;
