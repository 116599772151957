import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import { useInView } from 'react-intersection-observer';


const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "HomeSplit.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div ref={ref} id="#about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className={`lg:col-span-6 flex flex-col gap-6 overflow-hidden ${inView ? 'animate-slideRight' : 'opacity-0'}`}>
            <Eyebrow label="ABOUT US" />
            <h2 className="font-display md:text-display-lg text-display-md font-normal pb-4">
              Elevating Homes with Quality Roofs at Honest Prices.
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              TruCost Roofing specializes in residential roof replacement services. 
              We focus on delivering high-quality, cost-effective roofing solutions 
              tailored to homeowners' needs. 
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Our team of experts ensures a smooth, efficient process from initial assessment 
              to final installation, using durable materials for long-lasting protection. 
              Committed to customer satisfaction, TruCost Roofing is your reliable partner 
              for all residential roofing replacements.
            </p>
          </div>
          <div className={`lg:col-span-6 flex flex-col gap-8 relative overflow-hidden ${inView ? 'animate-slideLeft' : 'opacity-0'}`}>
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
