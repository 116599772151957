import React, {useState, useEffect} from "react";
import { useInView } from 'react-intersection-observer';


const Metrics = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  
  const [satisfaction, setSatisfaction] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    let interval;
  
    if (inView) {
      interval = setInterval(() => {
        setSatisfaction((prev) => (prev < 100 ? prev + 1 : prev));
        setEmployees((prev) => (prev < 40 ? prev + 1 : prev));
        setProjects((prev) => (prev < 790 ? prev + 10 : prev)); // Increment by 10 for larger numbers
      }, 10); // Adjust time interval as needed
    }
  
    return () => clearInterval(interval);
  }, [inView]);
  

  return (
    <div ref={ref}>
      <div className="container mx-auto">
        <div className="flex flex-col">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-8">
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                {satisfaction}%
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                SATISFIED <br></br>CLIENTS
              </p>
            </div>
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                {employees}
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                STATES<br></br>SERVICED
              </p>
            </div>
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                {projects}
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                HOMES SERVICED <br></br>IN THE US
              </p>
            </div>
          </div>
          <div className="md:mt-20 md:pb-12 mt-12 pb-12">
            <hr className="text-neutral-300"></hr>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Metrics;
