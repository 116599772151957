import React from "react";
import { useInView } from 'react-intersection-observer';

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });


  return (
    <div id="#services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div ref={ref} className={`lg:col-span-8 overflow-hidden ${inView ? 'animate-slideRight' : 'opacity-0'}`}>
              <Eyebrow label="OUR SERVICES" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                We provide the <span className="italic">best solutions</span>{" "}
                for your dream home
              </h2>
            </div>
          </div>
          <div ref={ref} className={`flex lg:flex-row flex-col gap-8 overflow-hidden ${inView ? 'animate-slideLeft' : 'opacity-0'}`}>
            <ServiceItem
              icon={ConstructionIcon}
              title="Consultation"
              description="Reach out to TruCost Roofing to get a quote. 
                A roofing expert from TruCost Roofing will schedule a time to come up with a plan.
                TruCost Roofing ensures transparency with no hidden fees, providing you with honest 
                pricing for the highest quality work."
            />
            <ServiceItem
              icon={RenovationIcon}
              title="Custom Plan"
              description="Based on the roof, we provide a customized 
                roofing plan that suits your home's specific needs.
                This plan includes material selection, design, and a timeline that respects your schedule."
            />
            <ServiceItem
              icon={ArchitectureIcon}
              title="Installation"
              description="Our skilled team carries out the roofing 
                installation efficiently and with minimal disruption to your daily routine.
                We use high-quality materials and the latest techniques to ensure a durable and aesthetically pleasing roof."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
