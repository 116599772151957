import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';

const Awards = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    // Load the Fouita script
    const script = document.createElement('script');
    script.src = "https://cdn.fouita.com/widgets/0x1035d8.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <div ref={ref} className={`pt-4 overflow-hidden ${inView ? 'animate-slideLeft' : 'opacity-0'}`}>
        <div className="container mx-auto">
          <div data-key="Google Reviews Playful Carousel" class="ft" id="ftd8mvuj4j"></div>
        </div>
      </div>
      <div className="md:mt-20 md:pb-12 mt-12 pb-12">
        <hr className="text-neutral-300"></hr>
      </div>
    </>
  );
};

export default Awards;
